// Please contact devops if you need any changes in this file
// because this file is recreated by the deploy script for
// staging on demand
import { applyFavIconAndTitle, sharedEnvironment } from './environment.common';

const RAILS_PROTO = 'https';
const RAILS_HOST = `staging01.dev161.com`; // do not touch, string gets replaced during deploy
const RAILS_FULLHOST = `${RAILS_PROTO}://${RAILS_HOST}`;

const SAFE_CREATIVES_PROTO = RAILS_PROTO;
const SAFE_CREATIVES_HOST = `staging01.creative-serving.org`; // do not touch, string gets replaced during deploy
const SAFE_CREATIVES_FULLHOST = `${SAFE_CREATIVES_PROTO}://${SAFE_CREATIVES_HOST}`;

const CB_URL = 'https://velocity-staging00.dev161.com/';
const CB_ACCOUNT_ID = 1159;
const DOOH_URL = 'https://doohplanner.dev161.com/';

export const environment = {
    ...sharedEnvironment,
    production: false,
    env: `staging1`,
    api: {
        host: `${RAILS_FULLHOST}`,
        base: `${RAILS_FULLHOST}/api/v3`,
        internal: `${RAILS_FULLHOST}/api/internal`,
        safeCreatives: `${SAFE_CREATIVES_FULLHOST}/api/internal`,
        refresh: 'tokens/refresh',
        login: 'tokens'
    },
    cretiveBuilderUrl: CB_URL,
    cretiveBuilderAccountId: CB_ACCOUNT_ID,
    doohPlannerUrl: DOOH_URL
};

applyFavIconAndTitle();
