/*
 * This is public contract between SDK implementation and fronted client.
 * Here there should be only:
 * - interfaces that are consumed by frontend
 * - all implementations of clients eg. IAdvertiserClient has AdvertiserClient
 */
export {
    Filter,
    ICollection,
    ICollectionMeta,
    ICond,
    IOrderBy,
    IParam,
    IRecursiveArray,
    IRequestOptions,
    IResource,
    ISearch,
    ISearchExpr,
    Operation,
    Order
} from './common/interfaces';

export { ErrorResponse, IErrorResponse, IBulkResponse } from './common/errors';

export {
    BaseApiClient,
    BasePersistentApiClient,
    IInstanceCodeClient,
    instanceCodeClientT
} from './common/index';

export { escapeHtml } from './common/html-escaper';

export {
    INSTANCE_KEY
} from './common/constants';

export {
    IInventory
} from './common/inventory';

// resources
export * from './account/interfaces';
export * from './dooh_publisher/interfaces';
export * from './dooh_channel/interfaces';
export * from './dooh_network/interfaces';
export * from './dooh_screen/interfaces';
export * from './event_name/interfaces';
export * from './ad-server/interfaces';
export * from './advertiser/interfaces';
export * from './agency/interfaces';
export * from './browser/interfaces';
export * from './campaign/interfaces';
export {
    ICampaignInternetServiceProvider,
    ICampaignInternetServiceProviderCollection,
    ICampaignInternetServiceProviderClient,
    ICampaignInternetServiceProviderClientFactory
} from './campaign-internet-service-provider/interfaces';
export * from './city/interfaces';
export * from './content-category/interfaces';
export * from './rule/interfaces';
export * from './country/interfaces';
export * from './creative/interfaces';
export * from './creative/preview_track_clicks/interfaces';
export * from './creative-file/interfaces';
export {
    ICreativeSubmission,
    ICreativeSubmissionClient,
    ISubmissionStatus,
    Approver
} from './creative/submission/interfaces';
export {
    ICreativeAttribute, ICreativeAttributeCollection, ICreativeAttributeClient
} from './creative/attributes/interfaces';
export * from './data-provider/interfaces';
export * from './data-targeting-set/interfaces';
export * from './device-type/interfaces';
export * from './direct-deal/interfaces';
export * from './deal-id-analyzer/interfaces';
export * from './device/interfaces';
export * from './external-id/interfaces';
export {
    IGoogleVertical,
    IGoogleVerticalClient,
    IGoogleVerticalCollection,
} from './google-vertical/interfaces';
export * from './site/interfaces';
export * from './tech-fee/interfaces';
export * from './third-party-data-provider/interfaces';
export * from './third-party-data-provider/segment/interfaces';
export * from './direct-deal/interfaces';
export {
    IDomain,
    IDomainBulk,
    IDomainBulkCollection,
    IDomainClient,
    IDomainCollection
} from './domain/interfaces';
export {
    IDomainSet,
    IDomainSetClient,
    IDomainSetCollection
} from './domain-set/interfaces';
export {
    ISupplySet,
    ISupplySetClient,
    ISupplySetCollection
} from './supply-set/interfaces';
export {
    ISection,
    ISectionClient,
    ISectionCollection,
    SectionPricingType,
    ISectionError
} from './section/interfaces';
export * from './exchange-rate/interfaces';
export {
    IIabCategory,
    IIabCategoryClient,
    IIabCategoryCollection
} from './iab-category/interfaces';
export * from './change/interfaces';
export * from './common/favoriteable/interfaces';
export {
    IComment,
    IPersistentComment,
    ICommentClient,
    ICommentCollection
} from './comment/interfaces';
export {
    IContentCategory,
    IContentCategoryClient,
    IContentCategoryCollection
} from './content-category/interfaces';
export * from './rule/interfaces';
export * from './conversion-pixel/interfaces';
export * from './campaign-conversion-pixel/interfaces';
export * from './campaign/campaign-inventory-source/interfaces';
export {
    CampaignMailAttributes,
    ICampaignConfirmationEmail,
    ICampaignConfirmationEmailClient
} from './campaign/campaign-confirmation-email/interfaces';
export {
    ICampaignsDirectDeal, ICampaignsDirectDealCollection
} from './campaigns-direct-deal/interfaces';
export * from './grid-view/interfaces';
export {
    IInventorySet,
    IInventorySetClient,
    IInventorySetCollection
} from './inventory-set/interfaces';
export * from './instance/interfaces';
export * from './targeting-default/interfaces';
export {
    IInternetServiceProvider,
    IInternetServiceProviderClient,
    IInternetServiceProviderCollection
} from './internet-service-provider/interfaces';
export * from './inventory-source/interfaces';
export * from './language/interfaces';
export * from './market/interfaces';
export * from './media-plan/interfaces';
export * from './mobile-app/interfaces';
export {
    IMobileAppStatistic, StatisticPeriod
} from './mobile-app-statistic/interfaces';
export * from './note/interfaces';
export * from './mobile-carrier/interfaces';
export {
    IPixel,
    IPixelClient,
    IPixelCollection
} from './pixel/interfaces';
export {
    IPixelOverview,
    IPixelOverviewClient,
    IPixelOverviewParams,
    IPixelOverviewCollection
} from './pixel-overview/interfaces';
export * from './operating-system/interfaces';
export {
    IGoogleOperatingSystemClient,
    IGoogleOperatingSystem,
    IGoogleOperatingSystemCollection
} from './google-operating-system/interfaces';
export {
    IGoogleDeviceCategoryClient,
    IGoogleDeviceCategory,
    IGoogleDeviceCategoryCollection
} from './google-device-categories/interfaces';
export {
    IPartialCreative,
    IPartialCreativeCollection
} from './partial-creative/interfaces';
export {
    IPartialCreativeFile,
    IPartialCreativeFileClient,
    IPartialCreativeFileCollection
} from './partial-creative-file/interfaces';
export * from './piggyback-url/interfaces';
export * from './privilege/interfaces';
export * from './real-time-data/interfaces';
export * from './region/interfaces';
export * from './retargeting-pixel/interfaces';
export * from './role/interfaces';
export * from './role-template/interfaces';
export * from './rtb-product/interfaces';
export * from './rtb-url/interfaces';
export * from './rtb-seat/interfaces';
export {
    IChange,
    IChangeCollection,
    IChangeClient
} from './change/interfaces';
export {
    ISize,
    ISizeCollection,
    ISizeClient
} from './size/interfaces';
export * from './targeting-set/interfaces';
export * from './third-party-data-provider/interfaces';
export {
    IThirdPartyTechnologyPartner,
    IThirdPartyTechnologyPartnerClient,
    IThirdPartyTechnologyPartnerCollection,
} from './third-party-technology-partner/interfaces';
export {
    IThirdPartyDataSegment,
    IThirdPartyDataSegmentClient,
    IThirdPartyDataSegmentCollection,
} from './third-party-data-provider/segment/interfaces';
export * from './custom-data-segment/interfaces';
export * from './hyperlocal-data-segment/interfaces';
export * from './feature/interfaces';
export * from './feature-reminder/interfaces';
export {
    IThirdPartyPixelTracking,
    IThirdPartyPixelTrackingClient,
    IThirdPartyPixelTrackingClientFactory,
    IThirdPartyPixelTrackingCollection,
    DeliveryType
} from './third-party-pixel-tracking/interfaces';
export { IUser, IUserCollection, IUserClient } from './user/interfaces';
export * from './user/password-reset.service';
export {
    IUserTemporaryAccount,
    IUserTemporaryAccountCollection,
    IUserTemporaryAccountClient
} from './user/temporary-account/interfaces';
export {
    IUserNotification,
    IUserNotificationClient,
    IUserNotificationCollection
} from './user-notification/interfaces';
export {
    IUserPreference,
    IUserPreferenceClient,
    IUserPreferenceCollection
} from './user-preference/interfaces';
export {
    IGeneralReportClient, IGeneralReport, IGeneralReportResult,
    IGeneralReportCollection, ISimpleResult
} from './general-report/interfaces';
export {
    Status as GeneralReportStatus,
    Filters as GeneralReportFilters,
    Measure as GeneralReportMeasure,
    Period as GeneralReportPeriod,
    Interval as GeneralReportInterval,
    Grouping as GeneralReportGrouping,
    FilterAssociation as GeneralReportFilterAssociation,
    FilterCurrency as GeneralReportFilterCurrency,
    ReportScheduleType as GeneralReportScheduleType,
    ReportSendAtType as GeneralReportSendAtType,
    Result as GeneralReportResult
} from './general-report/types';
export {
    IInvoiceReportClient, IInvoiceReport, IInvoiceReportCollection,
} from './invoice-report/interfaces';
export {
    Status as InvoiceReportStatus,
    Filters as InvoiceReportFilters,
    Grouping as InvoiceReportGrouping,
    FilterAssociation as InvoiceReportFilterAssociation,
    Interval as InvoiceReportInterval,
    Period as InvoiceReportPeriod,
    Measure as InvoiceReportMeasures
} from './invoice-report/types';
export {
    IForecastReport, IForecastReportCollection,
} from './forecast-report/interfaces';
export {
    Status as ForecastReportStatus,
    Filters as ForecastReportFilters,
    Grouping as ForecastReportGrouping,
    FilterAssociation as ForecastReportFilterAssociation,
    Measure as ForecastReportMeasures
} from './forecast-report/types';
export {
    IReachAndFrequencyReport, IReachAndFrequencyReportCollection,
} from './reach-and-frequency-report/interfaces';
export {
    Status as ReachAndFrequencyReportStatus,
    Filters as ReachAndFrequencyReportFilters,
    Interval as ReachAndFrequencyReportInterval,
    Period as ReachAndFrequencyReportPeriod,
    Grouping as ReachAndFrequencyReportGrouping,
    FilterAssociation as ReachAndFrequencyReportFilterAssociation,
    Measure as ReachAndFrequencyReportMeasures
} from './reach-and-frequency-report/types';
export {
    IDesignatedMarketArea, IDesignatedMarketAreaClient, IDesignatedMarketAreaCollection
} from './designated-market-area/interfaces';
export * from './impersonation/interfaces';
export * from './section/interfaces';
export * from './publisher/interfaces';
export * from './tracking-urls/interfaces';
export {
    ITagGeneration,
    ITagGenerationClient,
    ITagGenerationCollection,
    ITagGenerationResult,
    Kind
} from './tag-generation/interfaces';
export {
    IPixelGeneration,
    IPixelGenerationClient,
    IPixelGenerationCollection,
    IPixelGenerationResult,
} from './pixel-generation/interfaces';
export {
    IAdjusterThirdpartyProvider,
    IAdjusterThirdpartyProviderClient
} from './adjuster-thirdparty-provider/interfaces';

// Clients
export { ChangeClient } from './change/change.service';
export { CommentClient } from './comment/comment.service';

export { AccountClient } from './account/account.service';
export { DoohPublisherClient } from './dooh_publisher/dooh-publisher.service';
export { DoohChannelClient } from './dooh_channel/dooh-channel.service';
export { DoohNetworkClient } from './dooh_network/dooh-network.service';
export { DoohScreenClient } from './dooh_screen/dooh-screen.service';
export { EventNameClient } from './event_name/event_name.service';
export { AdServerClient } from './ad-server/ad-server.service';
export { AdvertiserClient } from './advertiser/advertiser.service';
export { AgencyClient } from './agency/agency.service';
export { BrowserClient } from './browser/browser.service';
export { GoogleBrowserClient } from './google-browser/google_browser.service';
export { IGoogleBrowserClient, IGoogleBrowser, IGoogleBrowserCollection } from './google-browser/interfaces';
export { CampaignClient } from './campaign/campaign.service';
export { CityClient } from './city/city.service';
export { ConversionPixelClient } from './conversion-pixel/conversion-pixel.service';
export {
    CampaignConversionPixelClient,
    CampaignConversionPixelClientFactory
} from './campaign-conversion-pixel/campaign-conversion-pixel.service';
export {
    CampaignInventorySourceClient,
    CampaignInventorySourceClientFactory
} from './campaign/campaign-inventory-source/campaign-inventory-source.service';
export {
    CampaignInternetServiceProviderClient,
    CampaignInternetServiceProviderClientFactory
} from './campaign-internet-service-provider/campaign-internet-service-provider.service';
export {
    CampaignConfirmationEmail,
    CampaignConfirmationEmailClient,
    CampaignConfirmationEmailClientFactory
} from './campaign/campaign-confirmation-email/campaign-confirmation-email.service';
export { ContentCategoryClient } from './content-category/content-category.service';
export { RuleClient } from './rule/rule.service';
export { CountryClient } from './country/country.service';
export {
    PreviewTrackClickClient, PreviewTrackClickClientFactory, TrackClickUrlFactory
} from './creative/preview_track_clicks/preview_track_click.service';
export { CreativeAttributeClient } from './creative/attributes/creative-attribute.service';
export { CreativeClient } from './creative/creative.service';
export { CreativeSubmissionClient } from './creative/submission/creative-submission.service';
export { CustomDataSegmentClient } from './custom-data-segment/custom-data-segment.service';
export { DataProviderClient } from './data-provider/data-provider.service';
export { DataTargetingSetClient } from './data-targeting-set/data-targeting-set.service';
export {
    DesignatedMarketAreaClient, DesignatedMarketArea
} from './designated-market-area/designated-market-area.service';
export { DeviceClient } from './device/device.service';
export { DeviceTypeClient } from './device-type/device-type.service';
export { DirectDealClient } from './direct-deal/direct-deal.service';
export { DealIdAnalyzerClient } from './deal-id-analyzer/deal-id-analyzer.service';
export { DomainClient } from './domain/domain.service';
export { DomainSetClient } from './domain-set/domain-set.service';
export { ExchangeRateClient } from './exchange-rate/exchange-rate.service';
export { ExternalIdClientFactory, ExternalIdClient } from './external-id/external-id.service';
export { FeatureClient } from './feature/feature.service';
export { FeatureReminderClient } from './feature-reminder/feature-reminder.service';
export { GeneralReportClient } from './general-report/general-report.service';
export { HyperlocalDataSegmentClient } from './hyperlocal-data-segment/hyperlocal-data-segment.service';
export { InvoiceReportClient } from './invoice-report/invoice-report.service';
export { ForecastReportClient } from './forecast-report/forecast-report.service';
export { ReachAndFrequencyReportClient } from './reach-and-frequency-report/reach-and-frequency-report.service';
export { IBaseApiClient } from './common/interfaces';
export { IPersistentApiClient, IBasePersistentApiClient } from './common/interfaces';
export { IabCategoryClient } from './iab-category/iab-category.service';
export { GoogleVerticalClient } from './google-vertical/google-vertical.service';
export {
    GoogleProgrammaticInventoryClient
} from './google-programmatic-inventory/google-programmatic-inventory.service';
export { GridViewClient } from './grid-view/grid-view.service';
export { InstanceClient } from './instance/instance.service';
export { InternetServiceProviderClient } from './internet-service-provider/internet-service-provider.service';
export { InventorySourceClient } from './inventory-source/inventory-source.service';
export { InventorySetClient } from './inventory-set/inventory-set.service';
export { LanguageClient } from './language/language.service';
export { MarketClient } from './market/market.service';
export { MediaPlanClient } from './media-plan/media-plan.service';
export { MobileAppClient } from './mobile-app/mobile-app.service';
export { MobileCarrierClient } from './mobile-carrier/mobile-carrier.service';
export { OperatingSystemClient } from './operating-system/operating-system.service';
export { GoogleOperatingSystemClient } from './google-operating-system/operating-system.service';
export { PartialCreativeFileClient } from './partial-creative-file/partial-creative-file.service';
export { PiggybackUrlClient, PiggybackUrlClientFactory } from './piggyback-url/piggyback-url.service';
export { PixelClient } from './pixel/pixel.service';
export { PixelOverviewClient } from './pixel-overview/pixel-overview.service';
export { Platform161TechFeeClient } from './tech-fee/tech-fee.service';
export { PrivilegeClient } from './privilege/privilege.service';
export { PublisherClient } from './publisher/publisher.service';
export { RealTimeDataClient } from './real-time-data/real-time-data.service';
export { RegionClient } from './region/region.service';
export { RetargetingPixelClient } from './retargeting-pixel/retargeting-pixel.service';
export { RoleClient } from './role/role.service';
export { RoleTemplateClient } from './role-template/role-template.service';
export { GoogleDeviceCategoryClient } from './google-device-categories/google-device-category.service';
export { RtbProductClient } from './rtb-product/rtb-product.service';
export { RtbUrlClient, RtbUrlClientFactory } from './rtb-url/rtb-url.service';
export { SectionClient } from './section/section.service';
export { SiteClient } from './site/site.service';
export { SizeClient } from './size/size.service';
export { SupplySetClient } from './supply-set/supply-set.service';
export { TargetingSetClient } from './targeting-set/targeting-set.service';
export { ThirdPartyDataProviderClient } from './third-party-data-provider/third-party-data-provider.service';
export { ThirdPartyDataSegmentClient } from './third-party-data-provider/segment/third-party-data-segment.service';
export {
    CampaignThirdPartyDataSegmentClient
} from './third-party-data-provider/segment/campaign-third-party-data-segment.service';
export {
    ThirdPartyPixelTrackingClient, ThirdPartyPixelTrackingClientFactory
} from './third-party-pixel-tracking/third-party-pixel-tracking.service';
export {
    ThirdPartyTechnologyPartnerClient
} from './third-party-technology-partner/third-party-technology-partner.service';
export { TrackingUrlClient } from './tracking-urls/tracking-urls.service';
export { UserClient } from './user/user.service';
export { UserNotificationClient } from './user-notification/user-notification.service';
export { UserTemporaryAccountClient } from './user/temporary-account/temporary-account.service';
export { UserPreferenceClient } from './user-preference/user-preference.service';
export { TagGenerationClient } from './tag-generation/tag-generation.service';
export { PixelGenerationClient } from './pixel-generation/pixel-generation.service';
export { CampaignsDirectDealClient } from './campaigns-direct-deal/campaigns-direct-deal.service';
export { AdjusterThirdpartyProviderClient } from './adjuster-thirdparty-provider/adjuster-thirdparty-provider.service';

// Authentication
export {
    AuthGuard,
    AuthenticationService,
    AuthConfigConsts,
    AuthHttp,
} from './authentication/authentication.service';

// Impersonation
export { Impersonation, IMPERSONATE_KEY } from './impersonation/impersonation.service';
